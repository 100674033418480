// react plugin used to create charts
import { Bar, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { CustomLoadingOverlay, LostConnectionOverlay } from "../components/Utils/nodata";
import React, { useEffect, useState } from "react";
// core components
import {
  chartExample1,
  chartExample2,
  stackedBar,
  chartOptions,
  parseOptions,
} from "variables/charts.js";

// javascipt plugin for creating charts
import Chart from "chart.js";
import Header from "components/Headers/Header.js";

import { Calendar } from 'primereact/calendar';

import { addLocale, locale } from 'primereact/api';

import { getProcessByMonthYear } from "services/processService";

import { SelectButton } from 'primereact/selectbutton';

// node.js library that concatenates classes (strings)
import classnames from "classnames";
import ProtectedRoute from "auth/protectedRoute";
import { getProcessesInfo } from "services/processService";

const Index = (props) => {


  const [activeNav, setActiveNav] = useState(0);
  const [chartExample1Data, setChartExample1Data] = useState(["assunto", "subassunto", "nucleo"]);

  // novos states
  const [infoProcessHeader, setInfoProcessHeader] = useState({});
  
  const [keyChild, setKeyChild] = useState(0);
  const [pieAssunto, setPieAssunto] = useState({});
  const [pieSubAssunto, setPieSubAssunto] = useState({});
  const [pieNucleo, setPieNucleo] = useState({});
  const [isLostConnection, setIsLostConnection] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [chartBarGroupbyUser, setchartBarGroupbyUser] = useState([])
  const [chartBarUser, setchartBarUser] = useState([])

  const [processByYear, setProcessByYear] = useState(false)

  const [date, setDate] = useState(new Date());

  const [labelMonth, setLabelMonth] = useState();
  const [dateDaysMonth, setDateDaysMonth] = useState();

  const daysinmonth = (month) => {
    const today = new Date();
    const year = today.getFullYear();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    return Array.from({length: daysInMonth}, (v, k) => k+1);
  }

  const palette = [
    "#0B3D91",
    "#2E2E2E",
    "#F4F4F4",
    "#6D001A",
    "#5A769A",
    "#1C1C1C",
    "#9E9E9E",
    "#2C5364",
    "#EFEFEF",
    "#4B0082"
  ]

  function generatePieChart(nucleo, assunto, subassunto){

      setLabelMonth(daysinmonth(new Date().getMonth()))

      const nucleoModel = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: palette
          },
        ],
      }

      const assuntoModel = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: palette
          },
        ],
      }

      const subassuntoModel = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: palette
          },
        ],
      }

      nucleo.forEach(item => {
        nucleoModel.labels.push(item.category__name)
        nucleoModel.datasets[0].data.push(item.total)
      })

      assunto.forEach(item => {
        assuntoModel.labels.push(item.subject__name)
        assuntoModel.datasets[0].data.push(item.total)
      })

      subassunto.forEach(item => {
        subassuntoModel.labels.push(item.subsubject__name)
        subassuntoModel.datasets[0].data.push(item.total)
      })


      setPieNucleo(
        nucleoModel
      );

      setPieAssunto(
        assuntoModel
      );

      setPieSubAssunto(
        subassuntoModel
      );


  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setChartExample1Data("data" + index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultado = await getProcessByMonthYear(date.getMonth()+1, date.getFullYear());
        if(resultado.status != 200){
          console.log("result",resultado)
        }
        else{
          console.log("result",resultado)
          setLabelMonth(daysinmonth(date.getMonth()))
          setDateDaysMonth(() => {
            let array = [].fill(0, 0, daysinmonth(date.getMonth()).length)
            resultado.data.forEach(item => {
              array[item.day-1] = item.count
            })

            return array
          })

          setIsLoadingData(false)
        }
      }
      catch (error) {
        console.error('Erro ao buscar dados assíncronos:', error);
      }
    }
    fetchData();
  }, [date, processByYear]);

  useEffect(() => {
    setIsLoadingData(true)
    const fetchData = async () => {
      try {
        const resultado = await getProcessesInfo();
        if(resultado.status != 200){
        }else{

          // informações necessárias para o header
          setInfoProcessHeader(
            {
              'number_process': resultado.data.number_process,
              'number_process_without_classification': resultado.data.number_process_without_classification,
              'delivery_today_to_user': resultado.data?.delivery_today_to_user,
              'classified_today_by_user': resultado.data?.classified_today_by_user,
              'last_process_created': resultado.data?.last_process_created,
              'most_user_id': resultado.data?.most_user_classifier?.classifier_user,
              'most_user_first_name': resultado.data?.most_user_classifier?.classifier_user_first_name,
              'most_user_last_name': resultado.data?.most_user_classifier?.classifier_user_last_name,
            }
          )

          const processesByUser = {
            labels: [],
            datasets: [
              {
                label: "Classificados",
                data: [],
              },
              {
                label: "Sem classificação",
                data: [],
              },
            ],
          }

          if(resultado.data?.classified_or_not_by_user != null){
            resultado.data?.classified_or_not_by_user.forEach(item => {
              processesByUser.labels.push(item.classifier_user == null ? 'Sem classificador' : item.classifier_user_first_name + ' ' + item.classifier_user_last_name)
              processesByUser.datasets[0].data.push(item.classified)
              processesByUser.datasets[1].data.push(item.not_classified)
            })
          }

          setchartBarGroupbyUser(processesByUser)

          let distributed_classified_user = resultado.data?.distributed_and_classified_by_user_per_month

          if(distributed_classified_user != null){

            let distributed = Array(12).fill(0)
            let classified = Array(12).fill(0)
            distributed_classified_user.forEach(item => {
              distributed[item.distribution_date__month-1] = item.distributed
              classified[item.distribution_date__month-1] = item.classified
            })

            setchartBarUser({
              "distributed": distributed,
              "classified": classified
            })
  
          }

          let category_subject_subsubject = resultado.data?.number_process_by_category_subject_subsubject;

          generatePieChart(category_subject_subsubject.category,category_subject_subsubject.subject, category_subject_subsubject.subsubject)

          setIsLoadingData(false)
        } 
        
      } catch (error) {
        console.error('Erro ao buscar dados assíncronos:', error);
      }
    }

    fetchData();
  }, []); 

  addLocale('pt-br', {
    firstDayOfWeek: 0,
    dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    today: 'Hoje',
    clear: 'Limpar',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Wk'
  });

  locale('pt-br');


  return (
    <>
      <Header key={keyChild} processesInfo={infoProcessHeader} lostConnection={isLostConnection}/>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow" >
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      <ProtectedRoute permissionsAccepted={['view_process']} mode={'component'}>
                        Processos {
                          processByYear ? 'em' : 'por mês'
                        }
                      </ProtectedRoute>
                      <ProtectedRoute permissionsAccepted={['view_all_process']} mode={'component'}>
                        Processos por usuário
                      </ProtectedRoute>
                    </h6>
                    <ProtectedRoute permissionsAccepted={['view_process']} mode={'component'}>
                    {
                      processByYear ?
                      <h2 className="mb-0">{new Date().getFullYear()}</h2>
                      : 
                      <Calendar value={date} onSelect={async (e) => {
                        setDate(e.value);
                        setIsLoadingData(true)
                        let val =  await getProcessByMonthYear(e.value.getMonth()+1, e.value.getFullYear())
                        setLabelMonth(daysinmonth(e.value.getMonth()))
                        setDateDaysMonth(() => {
                          let array = [].fill(0, 0, daysinmonth(e.value.getMonth()).length)
                          val.data.forEach(item => {
                            array[item.day-1] = item.count
                          })

                          return array
                        })
                        setIsLoadingData(false)
                        // chama o backend
                      }}
                      onClearButtonClick={
                        (e) => {
                          setDate(new Date())
                      }}

                      view="month" dateFormat="mm/yy" showIcon showButtonBar/>
                    }
                    </ProtectedRoute>
                    <ProtectedRoute permissionsAccepted={['view_all_process']} mode={'component'}>
                      <h2 className="mb-0">{new Date().getFullYear()}</h2>
                    </ProtectedRoute>
                  </div>
                  <ProtectedRoute permissionsAccepted={['view_process']} mode={'component'}>

                    
                    <Button color="primary" size="sm" className="mr-2" onClick={() => setProcessByYear(!processByYear)}>
                      {
                        !processByYear && 
                        <i className="fas fa-arrow-left mr-2" />
                      }
                     {processByYear ? 'Por mês' : 'Este ano'}
                     {
                        processByYear &&
                        <i className="fas fa-arrow-right ml-2" />
                     }
                    </Button>
                     
                    
                  </ProtectedRoute>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                {!isLoadingData ? (
                  isLostConnection ? (
                    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                      {LostConnectionOverlay()}
                    </div>
                  ) : (
                    <>
                      <ProtectedRoute permissionsAccepted={['view_process']} mode={'component'}>
                        {processByYear ? <Bar data={{
                          labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
                          datasets: [
                            {
                              label: "Distribuídos ",
                              // data: groupProcessByYear(processData, new Date().getFullYear()),
                              data: chartBarUser?.distributed,
                              maxBarThickness: 10,

                            },
                            {
                              label: "Classificados ",
                              data: chartBarUser?.classified,
                              maxBarThickness: 10,
                              backgroundColor: '#11cdef'
                            }
                          ],
                        }} options={chartExample2.options} /> :

                        <Bar data={{
                          
                          labels: labelMonth.map(item => item.toString()),
                          datasets: [
                            {
                              label: "Classificados",
                              data: dateDaysMonth,  
                              maxBarThickness: 10,
                              backgroundColor: '#6673E5'

                            },
                          ],
                        }} options={{
                          // toltiop 
                          // dia (x) Classificados: (y)
                          tooltips: {
                            callbacks: {
                              label: function(tooltipItem, data) {
                                return 'Classificados: ' + tooltipItem.value
                              }
                            }
                          },
                        }} />}

                      </ProtectedRoute>
                      <ProtectedRoute permissionsAccepted={['view_all_process']} mode={'component'}>
                        <Bar data={
                          {
                            labels: chartBarGroupbyUser.labels,
                            datasets: [
                              {
                                label: "Sem classificação",
                                data: chartBarGroupbyUser.length == 0 ? [] : chartBarGroupbyUser.datasets[1].data,
                                maxBarThickness: 10,
                              },
                              {
                                label: "Classificados",
                                data: chartBarGroupbyUser.length == 0 ? [] : chartBarGroupbyUser.datasets[0].data,
                                maxBarThickness: 10,
                                backgroundColor: '#11cdef'
                              },
                            ],
                          }
                        } options={stackedBar.options} />
                      </ProtectedRoute>
                    </>
                  )
                ) : <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                  {CustomLoadingOverlay()}
                </div>}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="mb-5 mb-xl-0" xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  
                  <div className="col">
                    
                    <Nav className="d-flex justify-content-around" pills>
                      
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-2")}
                          onClick={() => {
                            setActiveNav(activeNav == 0 ? chartExample1Data.length - 1 : activeNav - 1)
                          }}
                        >
                          <span className="d-none d-md-block"><i className="fas fa-arrow-left" /> {activeNav == 0 ? chartExample1Data[chartExample1Data.length-1] : chartExample1Data[activeNav-1]}</span>
                          <span className="d-md-none"><i className="fas fa-arrow-left" /> {activeNav == 0 ? chartExample1Data[chartExample1Data.length-1] : chartExample1Data[activeNav-1]}</span>
                        </NavLink>
                      </NavItem>
                      <div className="d-flex justify-content-center flex-column">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                          Processos por
                        </h6>
                        <h2 className="mb-0">{chartExample1Data[activeNav]}</h2>
                      </div>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-2")}
                          data-toggle="tab"
                          onClick={() => {
                            setActiveNav(activeNav == (chartExample1Data.length -1) ? 0 : activeNav + 1)
                          }}
                        >
                          <span className="d-none d-md-block"><i className="fas fa-arrow-right" /> {activeNav == chartExample1Data.length-1 ?  chartExample1Data[0] : chartExample1Data[activeNav+1]}</span>
                          <span className="d-md-none"><i className="fas fa-arrow-right" /> {activeNav == 0 ? chartExample1Data[chartExample1Data.length-1] : chartExample1Data[activeNav-1]}</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">

                {!isLoadingData ? (
                  isLostConnection ? (
                    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                      {LostConnectionOverlay()}
                    </div>
                  ) : (
                    <Pie
                    data={chartExample1Data[activeNav] == 'assunto' ? pieAssunto : (
                      chartExample1Data[activeNav] == 'subassunto' ?
                      pieSubAssunto : pieNucleo)}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                  )
                ) : <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                  {CustomLoadingOverlay()}
                </div>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;