import '../../assets/css/rsuite.css';

import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';

import {
    Card,
    CardFooter,
    CardHeader,
    Container,
    Input,
    Label,
    Media,
    Row,
    Table,
} from "reactstrap";

import { useNavigate, useLocation, useParams  } from "react-router-dom";

import { Accordion, Timeline, Divider, MaskedInput, Input as InputRsuite, useToaster, Modal, Button, Tabs, Whisper, Tooltip, Loader } from 'rsuite';
import Drawer from 'rsuite/Drawer';
import Select from 'rsuite/SelectPicker'

// tree folder file
import { Tree } from 'rsuite';
import FolderFillIcon from '@rsuite/icons/FolderFill';
import PageIcon from '@rsuite/icons/Page';

import NotFound from 'views/pages/notfound';

import { Dropdown } from 'primereact/dropdown';

import { getProcessParams, updateProcess, deleteProcess } from 'services/processService';
import { getCategoryList } from 'services/categoryService';
import { getSubSubjectList, getSubSubjectsByIdSubject } from 'services/subsubjectService';
import { getSubjectList } from 'services/subjectService';
import { getDocsFromProcess, getProcessStructure, getDocument } from 'services/documentService';
import { MessageBox, messagesContent } from "../../components/Utils/MessageBox"
import { getAdditionalInfoDescript, hasRole } from 'auth/jwtUtils';
import { requestChangeProcessCategory, getRequests, deleteRequestById } from 'services/requestService';
import ProtectedRoute from 'auth/protectedRoute';

import './uniqprocess.css'

const alertFields = MessageBox("warning", "Atenção", messagesContent.common.alertFields)
const messageUpdateError = MessageBox("error", "Erro", messagesContent.process.messageUpdateError)
const messageUpdateSuccess = MessageBox("success", "Sucesso", messagesContent.process.messageUpdateSuccess)
const errorDeleteAlert = MessageBox("error", "Erro", messagesContent.process.errorDeleteAlert)
const successDeleteAlert = MessageBox("success", "Sucesso", messagesContent.process.successDeleteAlert)

export default function Uniqprocess() {

    const iframeSrcRef = useRef(null);

    const navigate = useNavigate();

    const location = useLocation();

    const processParam = useParams();

    const toaster = useToaster();

    // informações para editar o processo
    const [openEdit, setOpenEdit] = React.useState(false);
    const handleEditModalClose = () => setOpenEdit(false);
    const handleEditModalOpen = () => setOpenEdit(true);

    // informações para deletar o processo
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalClose = () => setModalOpen(false);
    const handleModalOpen = () => setModalOpen(true);

    // informações para classificar o processo
    const [openClassification, setOpenClassification] = React.useState(false);
    const handleModalClassificationClose = () => setOpenClassification(false);
    const handleModalClassificationOpen = () => setOpenClassification(true);

    const reportType = [{
        label: 'Núcleo incorreto', value: 0
    }]

    const [modalReport, setModalReport] = React.useState(false);
    const handleModalReportClose = () => setModalReport(false);
    const handleModalReportOpen = () => setModalReport(true);

    // modal cancel report
    const [modalcancelreport, setModalCancelReport] = React.useState(false);
    const [atualRequestId, setAtualRequestId] = React.useState();


    const [processo, setProcesso] = React.useState({});

    const [allFieldsFill, setAllFieldsFill] = useState([false, false, false, false]);

    const [numProcess, setNumProcess] = React.useState('');
    const [category, setCategory] = React.useState('');
    const [subject, setSubject] = useState('');
    const [subsubject, setSubsubject] = React.useState('');
    const [obs, setObs] = React.useState('');
    const [categoryList, setCategoryList] = React.useState([]);
    const [subjectList, setSubjectList] = React.useState([]);
    const [subsubjectList, setSubsubjectList] = React.useState([]);

    const [atualdoc, setAtualdoc] = React.useState();

    const [open, setOpen] = React.useState(true);

    // repetição de código, refatorar
    const STATUS = {
        'pendente': 'orange',
        'aprovado': 'green',
        'não aprovado': 'red',
    };

    function mimetypeConverter(mimetype){
        // pdf, html, xml
        if(mimetype == 'pdf'){
            return 'application/pdf';
        }else if(mimetype == 'html'){
            return 'text/html';
        }else if(mimetype == 'xml'){
            return 'text/xml';
        }else{
            return mimetype;
        }
    }
    // report error 
    const [typeError, setTypeError] = React.useState();
    const [justify, setJustify] = React.useState('');
    const [newCategory, setNewCategory] = React.useState();
    
    const [reportFieldsFill, setReportFieldsFill] = useState([false, false, false]);

    //status da busca
    const [statusPage, setStatusPage] = React.useState();

    // reports
    const [reports, setReports] = React.useState([]);

    // documentos
    const [loadignDocs, setLoadingDocs] = useState(false);
    const [loadingTree, setLoadingTree] = useState(true);
    const [find, setFind] = useState(true);
    const [textRender, setTextRender] = useState(<Loader content="Carregando documento..." size='sm' />);
    const [folderStructure, setFolderStructure] = useState([]);

    const [contentCache, setContentCache] = useState({});

    // atualiza dados
    const handleUpdate = () => {
       
        if(allFieldsFill.includes(false)){
            toaster.push(alertFields, {duration: 5000 })
            return;
        }

        const processData = { number: numProcess, category_id: category, subject_id: subject, subsubject_id: subsubject, obs: obs}
        const updateData = async () => {
            try {
              const resultado = await updateProcess(processo.id,processData);
              if(resultado.status != 200){
                toaster.push(messageUpdateError, {duration: 5000 })
              }else{
                toaster.push(messageUpdateSuccess, {duration: 5000 })
                //setProcesso(resultado.data.results[0]);
                setOpenEdit(false);
                navigate(`/gerenciar/processo/${resultado.data.number}`);
                window.location.reload();
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
        }

        updateData()
    }

    // reportar erro
    const handleReport = () => {
        if(!reportFieldsFill.includes(false)){

            const fetch = async () => {
                try {
                const resultado = await requestChangeProcessCategory(processo.id, newCategory, getAdditionalInfoDescript().id, justify);
                if(resultado.status != 201){
                    toaster.push(MessageBox("error", "Erro", "Ocorreu um erro em sua solicitação"), {duration: 5000 })
                }else{
                    toaster.push(MessageBox("success", "Sucesso", "Solicitação enviada"), {duration: 5000 })
                    setModalReport(false);
                }
                } catch (error) {
                    console.error('Erro ao buscar dados assíncronos:', error);
                }
            }

            // testa se o processo já foi reportado
            const fetchVerify = async () => {
                try {
                    const resultado = await getRequests(processo.id, '', getAdditionalInfoDescript().id, 'PENDENTE', 'process', '');
                    if(resultado.status == 200){
                        if(resultado.data.results.length != 0){
                            toaster.push(MessageBox("warning", "Atenção", "Não foi possível prosseguir, processo já reportado"), {duration: 5000 })
                            setModalReport(false);
                            return;
                        }else{
                            fetch();
                        }
                    }
                } catch (error) {
                    console.error('Erro ao buscar dados assíncronos:', error);
                }
            }
            fetchVerify();
        }else{
            toaster.push(alertFields, {duration: 5000 })
        }
    }

     // validador de campos do report de erro
     useEffect(() => {
        try{
            if(typeError === '' || typeError == null || typeError == undefined){
                document.getElementById('select-typeError').style.borderColor = 'red';
                setReportFieldsFill(() => {reportFieldsFill[0] = false; return reportFieldsFill;});
            }else{
                document.getElementById('select-typeError').style.borderColor = 'green';
                setReportFieldsFill(() => {reportFieldsFill[0] = true; return reportFieldsFill;});
            }

            if(justify == '' || justify == null || justify == undefined){
                document.getElementById('input-justify').style.borderColor = 'red';
                setReportFieldsFill(() => {reportFieldsFill[1] = false; return reportFieldsFill;});
            }else{
                document.getElementById('input-justify').style.borderColor = 'green';
                setReportFieldsFill(() => {reportFieldsFill[1] = true; return reportFieldsFill;});
            }

            if(newCategory == '' || newCategory == null || newCategory == undefined){
                document.getElementById('select-newCategory').style.borderColor = 'red';
                setReportFieldsFill(() => {reportFieldsFill[2] = false; return reportFieldsFill;});
            }else{
                document.getElementById('select-newCategory').style.borderColor = 'green';
                setReportFieldsFill(() => {reportFieldsFill[2] = true; return reportFieldsFill;});
            }
        }catch(error){
            
        }
    }, [typeError, justify, newCategory,modalReport])

    // deleta processo
    const handleDelete = () => {
        const handleDelete = async () => {
            try {
              const resultado = await deleteProcess(processo.id);
              if(resultado.status != 204){
                toaster.push(errorDeleteAlert, {duration: 5000 })
              }else{
                toaster.push(successDeleteAlert, {duration: 5000 })
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
          };

          handleDelete();
          handleModalClose();
          // wait 1s
            setTimeout(() => {
                navigate('/gerenciar/processo');
            }, 1000);
    }

    // verifica se o processo tem formato valido
    const validaFormatoProcesso = (processNumber) => {
        const regex = new RegExp('^\\d{7}-\\d{2}.\\d{4}.\\d{1}.\\d{2}.\\d{4}$');
        if(regex.test(processNumber) && processNumber.length === 25){
          return true;
        }
        return false
    }

    // função para editar o processo
    const handleClickEdit = (number, category, subject, subsubject, obs) => {
        setNumProcess(number);
        if(category != null){
            setCategory(category.id);
        }
        if(subject != null){
            setSubject(subject.id);
        }
        if(subsubject != null){
            setSubsubject(subsubject.id);
        }
        setObs(obs);
        setOpenEdit(true);
    }

    // funcao para classificar o processo
    const handleClickClassification = (number, category, subject, subsubject, obs) => {
        setNumProcess(number);
        setCategory(category?.id);
        setSubject(subject?.id);
        setSubsubject(subsubject?.id);
        setObs(obs);
        handleModalClassificationOpen();
    }

    // funcao para classificar o processo
    const handleClassification = () => {
        if(subject == '' || subject == null || subject == undefined || subsubject == '' || subsubject == null || subsubject == undefined){
            toaster.push(alertFields, {duration: 5000 })
            return;
        }

        const processData = { number: numProcess, category_id: category, subject_id: subject, subsubject_id: subsubject, obs: obs, classification_date: new Date()}
        const updateData = async () => {
            try {
              const resultado = await updateProcess(processo.id, processData);
              if(resultado.status != 200){
                toaster.push(messageUpdateError, {duration: 5000 })
              }else{
                toaster.push(messageUpdateSuccess, {duration: 5000 })
                handleModalClassificationClose();
                window.location.reload();
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
        }

        updateData();
    }

    
    // validador de campos
    useEffect(() => {
        try{
            if(numProcess != '' && numProcess.match(/\d{7}-\d{2}\.\d{4}\.\d\.\d{2}\.\d{4}/)){
                document.getElementById('masked-input').style.borderColor = 'green';
                setAllFieldsFill(() => {allFieldsFill[0] = true; return allFieldsFill;});
            }else{
                document.getElementById('masked-input').style.borderColor = 'red';
                setAllFieldsFill(() => {allFieldsFill[0] = false; return allFieldsFill;});
            }

            if(category != '' && category != null && category != undefined){
                document.getElementById('select-nucleo').style.borderColor = 'green';
                setAllFieldsFill(() => {allFieldsFill[1] = true; return allFieldsFill;});
            }else{
                document.getElementById('select-nucleo').style.borderColor = 'red';
                setAllFieldsFill(() => {allFieldsFill[1] = false; return allFieldsFill;});
            }


        }catch(error){
            
        }


    }, [numProcess, category, subject, subsubject, obs, openEdit])

    useEffect(() => {
        try{
            if(subject != '' && subject != null && subject != undefined){
                document.getElementById('select-assunto-classification').style.borderColor = 'green';
                setAllFieldsFill(() => {allFieldsFill[2] = true; return allFieldsFill;});
            }else{
                document.getElementById('select-assunto-classification').style.borderColor = 'red';
                setAllFieldsFill(() => {allFieldsFill[2] = false; return allFieldsFill;});
            }

            if(subsubject != '' && subsubject != null && subsubject != undefined){
                document.getElementById('select-subassunto-classification').style.borderColor = 'green';
                setAllFieldsFill(() => {allFieldsFill[3] = true; return allFieldsFill;});
            }else{
                document.getElementById('select-subassunto-classification').style.borderColor = 'red';
                setAllFieldsFill(() => {allFieldsFill[3] = false; return allFieldsFill;});
            }
        }catch(error){
            console.log(error)
        }

    }, [subject, subsubject, openClassification])

    // carregar núcleos
    useEffect(() => {

        const getCategories = async () => {
            const response = await getCategoryList();
            if (response.status === 200) {
                const data = response.data.results;
                setCategoryList(data.map((item) => {
                    return { label: item.name, value: item.id }
                }));
            }else{
                setCategoryList([]);
            }
        }

        getCategories();
    }, [])

    // carregar assuntos
    useEffect(() => {
        
        const getSubjects = async () => {
            const response = await getSubjectList();
            if (response.status === 200) {
                const data = response.data.results;
                setSubjectList(data.map((item) => {
                    return { label: item.name, value: item.id }
                }
            ));

            }
        }

        getSubjects();
    }, [])

    // carregar subassuntos
    useEffect(() => {
        const fetchData = async () => {
            try {
              const resultado = await getSubSubjectsByIdSubject(subject);
              if(resultado.status != 200){
                  setSubsubjectList([]);
              }else{
                    //mudança de estrutura
                    setSubsubjectList(resultado.data.results.map(category => (
                    {label: category.name, value: category.id})
                  ));
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
          };
          if(subject != ''){
            fetchData();
          }
    }, [subject])

    // carregar informações do processo
    React.useEffect(() => {
        // verifica se o formato do processo é valido
        if (!validaFormatoProcesso(processParam.id)) {
            setStatusPage(0);
        }else{
            setStatusPage(1);
        }

        // busca processo
        const getProcess = async () => {
            //coloquei para buscar apenas nos q ele é visível
            const response = await getProcessParams(processParam.id, '', '', '', '', '', '', '', 0, 10, false);
            if (response.status === 200) {
                const data = response.data.results[0];
                if(data == null){
                    setStatusPage(0);
                }else{
                    if((getAdditionalInfoDescript().id == data.classifier_user?.id) || hasRole('view_all_process') ){
                        setProcesso(data);
                    }else{
                        setStatusPage(0);
                    }
                }
            }
            else {
                setStatusPage(0);
            }
        }

        getProcess();
        

    }, [])

    // busca solicitacoes
    React.useEffect(() => {
        const fetch = async () => {
            try {
                const resultado = await getRequests(processo.id, '', getAdditionalInfoDescript().id, '', 'process', '');
                if(resultado.status == 200){
                    setReports(resultado.data.results);
                }
            } catch (error) {
                console.error('Erro ao buscar dados assíncronos:', error);
            }
        }
        fetch();
    }
    , [processo, modalReport, modalcancelreport])

    // cancelar solicitação
    const handleCancelRequest = () => {
        const fetch = async () => {
            try {
                const resultado = await deleteRequestById(atualRequestId);
                if(resultado.status === 204){
                    toaster.push(MessageBox("success", "Sucesso", "Solicitação cancelada"), {duration: 5000 })
                    setModalCancelReport(false);
                }else{
                    toaster.push(MessageBox("error", "Erro", "Ocorreu um erro em sua solicitação"), {duration: 5000 })
                }
            } catch (error) {
                console.error('Erro ao buscar dados assíncronos:', error);
            }
        }
        fetch();
    }

     // busca a estrutura do processo
     useEffect(() => {

        const fetch = async () => {
            setLoadingTree(true);
            try {
                const resultado = await getProcessStructure(processo.number);
                // if(resultado.response.status != 200 && resultado.response.status != 404){
                //     console.log("error")
                //     setFolderStructure([]);
                //     setLoadingTree(false);
                //     return;
                // }

                if(resultado?.response?.status == 404){
                    setFind(false);
                    setFolderStructure([]);
                    return;
                }

                setFolderStructure(Object.keys(resultado.data).map((key, index) => {
                    return {
                        label: key,
                        value: key,
                        children: resultado.data[key].documents.map((item) => {
                            return {
                                label: item.description,
                                value: item.identifier,
                                children: item.linked_documents.length === 0
                                    ? null  
                                    : item.linked_documents.map((child) => {
                                        return {
                                            label: child.description,
                                            value: child.identifier,
                                            children: null  
                                        };
                                    })
                            };
                        })
                    };
                }))
                // if(atualdoc == undefined){
                //     getDocs(processo.number, resultado.data.documents[0].identifier);
                // }
                setLoadingTree(false);
            } catch (error) {
                // console.error('Erro', error);
            }finally{
                setLoadingTree(false);
            }
        }
        fetch();
    }, [processo])

    // busca documentos do processo
    async function getDocs(processNumber, identifier) {
        setLoadingDocs(true);
    
        try {
            // Verificar cache
            if (contentCache[identifier]) {
                setAtualdoc(contentCache[identifier]);
                iframeSrcRef.current = URL.createObjectURL(toBlob(contentCache[identifier].content, contentCache[identifier].mimetype));
                return;
            }
    
            // Busca o documento
            const resultado = await getDocument(processNumber, identifier);
            if (resultado.status !== 200) {
                setAtualdoc([]);
                iframeSrcRef.current = '';
                return;
            }
    
            setAtualdoc(resultado.data);
            iframeSrcRef.current = URL.createObjectURL(toBlob(resultado.data.content, resultado.data.mimetype));
    
            // Atualizar o cache
            setContentCache(prev => ({ ...prev, [identifier]: resultado.data }));
        } catch (error) {
            // console.error("Erro ao buscar o documento:", error);
        } finally {
            setLoadingDocs(false);
        }
    }
    

    // to blob
    function toBlob(content, mimetype){
        const byteCharacters = atob(content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: mimetypeConverter(mimetype)});
        return blob;
    }

    useEffect(() => {
        if(!find){
            // console.log('entrou', find)
            setTextRender(
                    <h4 className='text-center'>Não foi possível encontrar documentos para este processo.</h4>
            );
        }
    }, [folderStructure])

    const fixTabIndex = () => {
        const drawer = document.querySelector('.rs-drawer-wrapper');
        if (drawer) {
            drawer.removeAttribute('tabindex') // Permite foco
        }
    };
    
    useEffect(() => {
        fixTabIndex();
    });

    return (
        <>        
            <div className="head bg-gradient-info pb-8 pt-5 pt-md-8">
                
            </div>

            <Container className="mt--7" fluid>

                {statusPage == 1 && <Row>
                    <div className="col">
                        <Card className="shadow pl-3 pr-3 pt-4 pb-4">
                        {/* botões */}
                        <div className='mr-8 mb-3 pl-3 pr-3 d-flex w-100 justify-content-between'>
                            <div className='d-flex justify-content-start'>
                                <Button onClick={() => {
                                    setOpen(!open);
                                }} color='info' size="sm">
                                    
                                    {open ? <i class="fa-solid fa-folder-open"></i> : <i class="fa-solid fa-folder"></i>}
                                </Button>
                            </div>
                            <div className=' d-flex w-100 justify-content-end'>
                                
                                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                                    <Tooltip>
                                        Reportar erro
                                    </Tooltip>
                                }>
                                    <Button style={{ backgroundColor: '#fb923c', color: 'white' }} className='mr-2' onClick={() =>
                                        { handleModalReportOpen();
                                        setTypeError();
                                        setJustify();
                                        setNewCategory();
                                        setReportFieldsFill([false, false, false]);
                                        }
                                    }>
                                        <i className="fas fa-triangle-exclamation"></i>
                                    </Button>
                                </Whisper>
                                <ProtectedRoute permissionsAccepted={['classification_process']} mode={'component'}>
                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                                        <Tooltip>
                                            Classificar processo
                                        </Tooltip>
                                    }>
                                        <Button style={{ backgroundColor: '#38bdf8', color: 'white' }} className='mr-2' onClick={() => {
                                            handleClickClassification(processo.number, processo.category, processo.subject, processo.subsubject, processo.obs);
                                        }}>
                                            <i class="fa-solid fa-stamp"></i>
                                        </Button>
                                    </Whisper>
                                </ProtectedRoute>
                                <ProtectedRoute permissionsAccepted={['change_process']} mode={'component'}>
                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                                        <Tooltip>
                                            Editar processo
                                        </Tooltip>
                                    }>
                                        <Button color='info' className='mr-2' onClick={() =>
                                            handleClickEdit(processo.number, processo.category, processo.subject, processo.subsubject, processo.obs)
                                        }>
                                            <i className="fas fa-edit"></i>
                                        </Button>
                                    </Whisper>
                                </ProtectedRoute>
                                <ProtectedRoute permissionsAccepted={['delete_process']} mode={'component'}>
                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                                        <Tooltip>
                                            Deletar processo
                                        </Tooltip>
                                    }>
                                        <Button style={{ backgroundColor: '#f87171', color: 'white' }} onClick={() => {
                                            handleModalOpen();
                                        }}>
                                            <i className="fas fa-trash"></i>
                                        </Button>
                                    </Whisper>
                                </ProtectedRoute>
                            </div>
                        </div>
                            <Accordion bordered>
                                <Accordion.Panel  header="Processo" disabled={false} defaultExpanded>
                                    {!loadingTree && folderStructure.length > 0 ?
                                    
                                    <div className='d-flex w-100'>
                                        <div className={open ? 'sidebar' : 'sidebar-closed'}>
                                            <Tree
                                                disabledItemValues={folderStructure.filter((item) => {
                                                    return item.children.length === 0
                                                }).map((item) => {return item.value})}
                                                scrollShadow
                                                className="w-10 mt-4"
                                                searchable
                                                height={600}
                                                style={{ maxHeight: '580px', maxWidth: '250px', marginRight: '10px'}}
                                                data={folderStructure}
                                                showIndentLine
                                                
                                                renderTreeNode={ node => {
                                                    return (
                                                        <>
                                                            {node.children ? <FolderFillIcon /> : <PageIcon />}
                                                            {" " + node.label}
                                                        </>
                                                    );
                                                }}
                                                
                                                onSelect={(node) => {
                                                    getDocs(processo.number, node.value);
                                                    // console.log(node)
                                                }}
                                            >

                                            </Tree>
                                        </div>
                                        <div className='w-100 d-flex justify-content-center align-items-center'>
                                            {
                                            atualdoc == undefined && !loadignDocs ?
                                            <div className="d-flex justify-content-center align-items-center">
                                                <span className="ml-2">
                                                    {/* se todos os childrens estao cheio */}
                                                    {folderStructure.some((item) => {
                                                        return item.children.length > 0
                                                    }) ? "Selecione um documento" : "Não há documentos para exibir"}
                                                </span>
                                            </div> :
                                            loadignDocs ? 
                                            <Loader content="Carregando documento..." size='sm' /> :
                                            <embed
                                                title={atualdoc?.description}
                                                // src={atualdoc == undefined ? '' : URL.createObjectURL(toBlob(atualdoc.content, atualdoc.mimetype))}
                                                src={iframeSrcRef.current}
                                                width="100%"
                                                height="600px"
                                                className='d-flex justify-content-center'
                                                allowFullScreen
                                                
                                            />
                                            
                                            }
                                        </div>
                                    </div> : <div className='d-flex w-100 justify-content-center align-items-center'>
                                            {textRender}
                                        </div>
                                    }
                                    {/* {!loadingTree && folderStructure.length == 0 &&
                                    <div>
                                        <h4 className='text-center'>Não foi possível encontrar documentos para este processo.</h4>
                                        <h6 className='text-center'>Verifique se o número do processo está correto.</h6>
                                    </div>
                                    } */}
                                </Accordion.Panel>

                                {/* <Accordion.Panel header="Movimentações" disabled={true}>
                                    <Timeline align='left' className='ml-4'>
                                        <Timeline.Item>
                                            <h5>2018-03-01 13:03:01</h5>
                                            <Label>Processo distribuído</Label>
                                        </Timeline.Item>
                                        <Timeline.Item>
                                            <h5>2018-03-02 13:03:01</h5>
                                            <Label>Processo classificado</Label>
                                        </Timeline.Item>
                                        <Timeline.Item>
                                            <h5>2018-03-10 13:03:01</h5>
                                            <Label>Processo redistribuído</Label>
                                        </Timeline.Item>
                                        <Timeline.Item>
                                            <h5>2018-03-15 13:03:01</h5>
                                            <Label>Data alterada</Label>
                                        </Timeline.Item>
                                    </Timeline>
                                </Accordion.Panel> */}
                                <Accordion.Panel header="Informações" defaultExpanded>
                                    <div className="d-flex flex-wrap justify-content-center w-100">
                                        <row className="d-flex flex-wrap justify-content-center flex-row w-100">
                                            <div className="d-flex flex-column mt-2 mr-md-4">
                                                <h4 className="">Número do Processo</h4>
                                                <Input className="form-control-alternative" style={{ width: "300px" }} type="text" value={processo?.number} disabled={true}/>
                                            </div>

                                            <div className="d-flex flex-column mt-2 mr-md-4">
                                                <h4 className="">Núcleo</h4>
                                                <Input className="form-control-alternative" style={{ width: "300px" }} type="text" value={processo.category?.name} readOnly/>
                                            </div>

                                            <div className="d-flex flex-column mt-2 mr-md-4" >
                                                <h4 className="">Assunto</h4>
                                                <Input className="form-control-alternative" style={{ width: "300px" }} type="text" value={processo.subject?.name} readOnly/>
                                            </div>
                                        </row>

                                        <row className="d-flex flex-wrap justify-content-center flex-row w-100">
                                            <div className="d-flex flex-column mt-2 mr-md-4">
                                                <h4 className="">Subassunto</h4>
                                                <Input className="form-control-alternative" style={{ width: "300px" }} type="text" value={processo.subsubject?.name} readOnly/>
                                            </div>

                                            <div className="d-flex flex-column mt-2 mr-md-4">
                                                <h4 className="">Responsável</h4>
                                                <Input className="form-control-alternative" style={{ width: "300px" }} type="text" value={
                                                    processo.classifier_user == null ? '' :
                                                    processo.classifier_user?.first_name + " " + 
                                                    processo.classifier_user?.last_name} readOnly/>
                                            </div>

                                            <div className="d-flex flex-column mt-2 mr-md-4">
                                                <h4 className="">Observação</h4>
                                                <Input className="form-control-alternative"  style={{ resize: "None", width: "300px" }} rows={6} type="textarea" value={processo.obs} readOnly/>
                                            </div>
                                        </row>
                                    </div>
                                </Accordion.Panel>
                                <Accordion.Panel header="Solicitações">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Novo núcleo</th>
                                                <th>Justificativa</th>
                                                <th>Comentário</th>
                                                <th>Status</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { reports.length == 0 ? 
                                            
                                            <tr><td colSpan="6" className="text-center">Nenhuma solicitação</td></tr> :
                                                reports.map((item) => {
                                                    return (
                                                        <tr>
                                                            <td><h5>Núcleo incorreto</h5></td>
                                                            <td>{item.category.name}</td>
                                                            <td>{item.justify}</td>
                                                            <td>{item.comment}</td>
                                                            <td><i class="fa-solid fa-circle fa-sm mr-2" style={{ color: 
                                                                                STATUS[item.status.toLowerCase()]
                                                                            }}></i>{item.status}</td>
                                                            <td>
                                                                {item.status == 'PENDENTE' && 
                                                                
                                                                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                                                                    <Tooltip>
                                                                        Cancelar solicitação
                                                                    </Tooltip>
                                                                }>
                                                                    <Button style={{ backgroundColor: '#F87171', color: 'white' }} className='mr-2' size="sm" onClick={() =>{
                                                                        setModalCancelReport(true)
                                                                        setAtualRequestId(item.id)
                                                                    }}>
                                                                        <i className="fa-solid fa-xmark"></i>
                                                                    </Button>
                                                                </Whisper>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    
                                </Accordion.Panel>
                            </Accordion>
                            
                        
                        </Card>
                    </div>
                </Row>}

                {
                    statusPage == 0 && <Row>
                        <NotFound />
                    </Row>
                }
            </Container>

            <Drawer backdrop="static" open={openEdit} onClose={handleEditModalClose} size={(window.innerWidth < 768) ? 'full' : 'sm'}>
                    <Drawer.Header className="pt-4">
                        <h4>Edição de processo</h4>
                    </Drawer.Header>
                    <Drawer.Body>
                            <Row className="mb-4">
                                <h4>Nº do processo</h4>
                                <MaskedInput placeholder="Digite o Nº do processo" value={numProcess} id="masked-input" mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/, /\d/, '-', /\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/,'.',/\d/,'.',/\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/]} onChange={setNumProcess}></MaskedInput>
                            </Row>
                            <Row className="mb-4">
                                <h4>Núcleo</h4>
                                <Select id="select-nucleo" value={category} onChange={setCategory} placeholder="Selecione um núcleo" data={categoryList} style={{ width: "100%" }} size="lg">
                                    
                                </Select>
                            </Row>
                            <Row className="mb-4">
                                <h4>Assunto</h4>
                                <Select id="select-assunto" value={subject} onChange={setSubject} data={subjectList} placeholder="Selecione um Assunto" style={{ width: "100%" }} size="lg" 
                                    onClean={() => {setSubsubject();}}
                                >
                                    
                                </Select>
                            </Row>
                            <Row className="mb-4">
                                <h4>Subassunto</h4>
                                <Select id="select-subassunto" value={subsubject} onChange={setSubsubject} data={subsubjectList} placeholder="Selecione um Subassunto" style={{ width: "100%" }} size="lg">
                                    
                                </Select>
                            </Row>
                            <Row className="mb-4">
                                <h4>Observação</h4>
                                <InputRsuite placeholder="Digite uma observação" id="obs" as="textarea" value={obs} rows={7} onChange={setObs}></InputRsuite>
                            </Row>
                            <Row>
                                <Button onClick={handleUpdate} color="primary" className="position-absolute bottom-0 end-0 right-0 mr-5 mb-4">Atualizar</Button>
                            </Row>
                    </Drawer.Body>
                </Drawer>

                <Modal open={modalOpen} onClose={handleModalClose}>
                    <Modal.Header>
                    <Modal.Title>Deletar processo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Deseja realmente deletar o processo? Essa ação é irreversível.
                    </Modal.Body>
                    <Modal.Footer>
                    <Button size="sm" onClick={handleModalClose} appearance="subtle">
                        Cancelar
                    </Button>
                    <Button size="sm" style={{ backgroundColor: '#f87171' }} onClick={handleDelete} appearance="primary">
                        Confirmar
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Modal open={modalReport} onClose={handleModalReportClose} role="alertdialog" closable={false} backdrop={'static'}>
                <Modal.Header>
                <Modal.Title>Reportar erro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: 25 }}>
                            <Label>Tipo de erro</Label>
                            <Select id="select-typeError" style={{ width: 224 }} placeholder="Tipo de erro" data={reportType} value={typeError} onChange={setTypeError} size="sm"></Select>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {
                                typeError == reportType[0].value && 
                                <>
                                    <Label>Novo núcleo</Label>
                                    <Select id="select-newCategory" style={{ width: 224 }} placeholder="Selecione um núcleo" data={categoryList.filter((item) => 
                                    {
                                        return item.value != processo?.category.id
                                    }
                                    )} value={newCategory} onChange={setNewCategory} size="sm"></Select>
                                </>
                            }
                        
                        </div>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <Label>Justificativa</Label>
                        <InputRsuite id="input-justify" as="textarea" rows={4} placeholder="Digite uma justificativa" value={justify} onChange={setJustify}></InputRsuite>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button size="sm" onClick={handleModalReportClose} appearance="subtle">
                    Cancelar
                </Button>
                <Button size="sm" color="primary" onClick={handleReport} appearance="primary">
                    Confirmar
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal open={modalcancelreport} onClose={handleModalClose}>
                <Modal.Header>
                <Modal.Title>Cancelar solicitação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja realmente cancelar a solicitação? Essa ação é irreversível.
                </Modal.Body>
                <Modal.Footer>
                <Button size="sm" onClick={() => setModalCancelReport(false)} appearance="subtle">
                    Cancelar
                </Button>
                <Button size="sm" style={{ backgroundColor: '#f87171' }} onClick={handleCancelRequest} appearance="primary">
                    Confirmar
                </Button>
                </Modal.Footer>
            </Modal>

            {/* modal de classificacao */}
            <Drawer backdrop="static" open={openClassification} onClose={handleModalClassificationClose} 
                size={(window.innerWidth < 768) ? 'full' : 'sm'}
            >
                <Drawer.Header className="pt-4">
                    <h4>Classificação de processo</h4>
                </Drawer.Header>
                <Drawer.Body>
                    <Row className="mb-4">
                        <h4>Nº do processo</h4>
                        <Input disabled={true} placeholder="Digite o Nº do processo" value={numProcess} id="masked-input-classification" mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/, /\d/, '-', /\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/,'.',/\d/,'.',/\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/]} onChange={setNumProcess}></Input>
                    </Row>
                    <Row className="mb-4">
                        <h4>Núcleo</h4>
                        <Input disabled={true} id="select-nucleo-classification" value={categoryList.find((c) => {
                            return c.value == category
                        })?.label} onChange={setCategory} placeholder="Selecione um núcleo" style={{ width: "100%" }} size="lg">
                        </Input>
                    </Row>
                    <Row className="mb-4">
                        <h4>Assunto</h4>
                        {/* <Select id="select-assunto-classification" value={subject} onChange={setSubject} data={subjectList} placeholder="Selecione um Assunto" style={{ width: "100%" }} size="lg"
                            onClean={() => {
                                setSubject('');
                                setSubsubject('');
                            }}
                        >
                            
                        </Select> */}

                        <Dropdown
                            value={subject}
                            onChange={(e) => { 
                                setSubject(e.target.value);
                                setSubsubject('');
                            }}
                            optionLabel="label"
                            emptyMessage="Sem dados"
                            options={subjectList}
                            style={{ width: '100%', borderColor: (subject == '' || subject == null || subject == undefined) ? 'red' : 'green' }}
                            placeholder="Selecione um assunto"
                            filter
                            
                        />
                    </Row>
                    <Row className="mb-4">
                        <h4>Subassunto</h4>
                        {/* <Select id="select-subassunto-classification" value={subsubject} onChange={setSubsubject} data={subsubjectList} placeholder="Selecione um Subassunto" style={{ width: "100%" }} size="lg"
                        onClean={() => {
                            setSubsubject('');
                            setSubject('');
                        }}>
                            
                        </Select> */}
                        <Dropdown
                            value={subsubject}
                            loading={subject == '' || subject == null || subject == undefined}
                            onChange={(e) => { 
                                setSubsubject(e.target.value);
                            }}
                            optionLabel="label"
                            emptyMessage="Sem dados"
                            options={subsubjectList}
                            style={{ width: '100%', borderColor: (subsubject == '' || subsubject == null || subsubject == undefined) ? 'red' : 'green' }}
                            placeholder="Selecione um subassunto"
                            filter
                        />
                    </Row>
                    <Row className="mb-4">
                        <h4>Observação</h4>
                        <InputRsuite placeholder="Digite uma observação" id="obs-classification" as="textarea" value={obs} rows={7} onChange={setObs}></InputRsuite>
                    </Row>
                    <Row className='d-flex justify-content-end'>
                        <Button onClick={handleClassification} color="primary" className="bottom-0 end-0 right-0 mb-4">Atualizar</Button>
                    </Row>
                </Drawer.Body>
            </Drawer>
        </>

    );
}