import axios from "axios";
import { getTokenDecript } from "../auth/jwtUtils";

const BASEURL = process.env.REACT_APP_BASE_URL;
const PROCESS_URL = process.env.REACT_APP_ENDPOINT_PROCESS
const TOKEN = process.env.REACT_APP_APPLICATION_TOKEN;

export async function getProcessList(page, size, bool) {
  //mudança
  var finalquery;
  if(bool){
    finalquery = '?page='+page+'&page_size='+size
  }else{
    finalquery = ''
  }

  try {
    const response = await axios.get(BASEURL+PROCESS_URL+finalquery, 
      {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }, timeout: 50000
      })
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function saveProcess(process){
  const transformedJson = JSON.stringify(process)
  try {
    const response = await axios.post(BASEURL+PROCESS_URL, transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }
  
  })
    if(response.status === 201) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function deleteProcess(id){
  try {
    const response = await axios.delete(BASEURL+PROCESS_URL+id, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 204) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function getProcessParams(number, category, subject, subsubject, userid, obs, isClassified, isReported, page, size, bool){
  if(category === undefined || category === null){
    category = ""
  }
  if(subject === undefined || subject === null){
    subject = ""
  }
  
  if(subsubject === undefined || subsubject === null){
    subsubject = ""
  }

  if(isClassified === undefined || isClassified === null || isClassified === "any"){
    isClassified = ""
  }

  if(isReported === undefined || isReported === null){
    isReported = false
  }

  var finalquery;
  if(bool){
    finalquery = '&page='+page+'&page_size='+size
  }else{
    finalquery = '';
  }
  try {
    const response = await axios.get(BASEURL+PROCESS_URL+'?number='+number+'&category='+category+'&subject='+subject+'&subsubject='+subsubject+'&classifier_user='+userid+'&obs='+obs+'&isClassified='+isClassified+
      '&isReported='+isReported+finalquery, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function cadastrarProcessoLote(listProcessos, nucleo){
  const transformedJson = JSON.stringify(
    {
      "processes": listProcessos,
      "category_id": nucleo
    })
  try {
    const response = await axios.post(BASEURL+PROCESS_URL+'add_process_batch/', transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
    
}

export async function updateProcess(id, data){
  const transformedJson = JSON.stringify(data)
  try {
    const response = await axios.put(BASEURL+PROCESS_URL+id+'/', transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function assignProcess(userId, listProcessIds, all_process, number, category, distributed, classified){
  const transformedJson = JSON.stringify(
    {
      "user_id": userId,
      "processes_ids": listProcessIds,
      "all_process": all_process,
      "number": number,
      "category": category,
      "distributed": distributed,
      "classified": classified
    })
  try {
    const response = await axios.post(BASEURL+PROCESS_URL+'assign/', transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function randomAssign(categoryId, processes_ids, all_process, number, category, distributed, classified){
  const transformedJson = JSON.stringify(
    {
      "category_id": categoryId,
      "processes_ids": processes_ids,
      "all_process": all_process,
      "number": number,
      "category": category,
      "distributed": distributed,
      "classified": classified
    })

  try {
    const response = await axios.post(BASEURL+PROCESS_URL+'random_assign/', transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function getProcessesInfo(){
  try {
    const response = await axios.get(BASEURL+PROCESS_URL+'infos_process/', {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function getProcessByMarker(number, category, distributed, classified, page, page_size){

  var finalquery='?';

  if(number !== undefined && number !== null && number !== ""){
    finalquery += 'number='+number
  }
  if(category !== undefined && category !== null && category !== ""){
    finalquery += '&category='+category
  }
  if(distributed !== undefined && distributed !== null && distributed !== ""){
    finalquery += '&distributed='+distributed
  }
  if(classified !== undefined && classified !== null && classified !== ""){
    finalquery += '&classified='+classified
  }

  finalquery += '&page='+page+'&page_size='+page_size

    try {
      const response = await axios.get(BASEURL+PROCESS_URL+'process_by_marker/'+ finalquery, {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+getTokenDecript(),
        'Application-Token': TOKEN,
      }})
      if(response.status === 200) {
        return response;
      }
      console.log(response)
    }catch(error) {
      console.log(error)
      return error
    }
}

export async function getProcessByMonthYear(month, year){
  try {
    const response = await axios.get(BASEURL+PROCESS_URL+'process_month/?month='+month+'&year='+year, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}